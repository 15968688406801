import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { StyledH1 } from "../theme/styles";
import styled from "styled-components";
import t from "../theme/theme";

const StyledCvSection = styled.div`
  display: flex;
  padding-right: ${t.spacing(1.5)};
  margin-bottom: ${t.spacing(3)};
`;

const StyledH1Ext = styled(StyledH1)`
  margin-bottom: 5vh;
`;

const StyledCvHeader = styled(StyledH1)`
  padding-top: 0;
`;

const StyledCvList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  color: ${t.blue};

  p {
    margin: 0;
  }

  li {
    margin-bottom: ${t.spacing(2)};
  }
`;

const StyledSubtitle = styled.h2`
  margin: 0;
  margin-bottom: ${t.spacing(1)};
  font-size: ${t.spacing(2)};
`;

const Year = styled.p`
  font-weight: bold;
`;

const NewsPage = (props) => {
  const data =
    props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter.section;
  return (
    <Layout location={props.location}>
      <StyledH1Ext>CV</StyledH1Ext>
      <div>
        {data.map((section) => {
          return (
            <StyledCvSection key={section.title}>
              <StyledCvHeader>{section.title}</StyledCvHeader>
              <div>
                {section.subsection.map((subsection, index) => {
                  return (
                    <div key={index}>
                      {subsection.subtitle && (
                        <StyledSubtitle>{subsection.subtitle}</StyledSubtitle>
                      )}
                      <StyledCvList>
                        {subsection.achievement.map((achievement, index) => (
                          <li key={index}>
                            <Year>{achievement.year} </Year>
                            <p>{achievement.details}</p>
                          </li>
                        ))}
                      </StyledCvList>
                    </div>
                  );
                })}
              </div>
            </StyledCvSection>
          );
        })}
      </div>
    </Layout>
  );
};

export default NewsPage;

export const pageQuery = graphql`
  query CvQuery {
    allFile(limit: 1, filter: { sourceInstanceName: { eq: "cv" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              section {
                title
                subsection {
                  subtitle
                  achievement {
                    year
                    details
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
